/* Header */

.Header-background {
    margin-top: -60px;
    min-height: 500px;
    background-image: linear-gradient(transparent, 95%, white), url("./header.jpg");
    background-position: center;
    background-size: cover;
}

.Header-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.Header-content h1 {
    margin: 0px;
    font-size: 2.0em;
    padding-top: 15px;
}

.Header-content h2 {
    margin: 0px;
    font-size: 1.75em;
    padding-bottom: 15px; }

.Header-logo {
    height: 45vmin;
    min-height: 250px;
    max-height: 300px;
    border-radius: 50%;
}

/*  Mobile */

@media (max-width: 768px) {
    .Header-background {
        height: 95vh;
    }

    .Info-grid {
        margin-top: 10px;
    }
}

/*  Non-mobile */

@media (min-width: 768px) {
    .Header-background {
        height: 75vh;
    }

    .Info-grid {
        margin-top: -35px;
    }
}

/* Info Grid */

.Info-grid {
    padding-right: 30px;
    padding-left: 30px;
}

/* About Grid */

.About-grid .divider {
    background-color: orange;
}

.About-grid {
    padding-top: 50px;
}

.About-grid h4 {
    color: orange !important;
}

.About-grid h1 {
    font-size: 4em !important; 
}

.About-grid .icon {
    margin-bottom: 25px;
}