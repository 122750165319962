.app-wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

footer {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #1B1C1D;
  height: "100px";
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

footer h4 {
  margin-top: 0px;
  margin-bottom: 5px;
}

footer button {
  margin-right: 5px;
  margin-bottom: 5px;
}